const defaultFont = {
  id: 'open-sans',
  kind: 'webfonts#webfont',
  family: 'Open Sans',
  category: 'sans-serif',
  variant: 'regular',
  version: 'v28',
  lastModified: '2022-03-02',
  files: {
    '300': 'http://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4nY1M2xLER.ttf',
    '300italic': 'http://fonts.gstatic.com/s/opensans/v28/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk5hkaVcUwaERZjA.ttf',
    '500': 'http://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjr0C4nY1M2xLER.ttf',
    '500italic': 'http://fonts.gstatic.com/s/opensans/v28/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk_RkaVcUwaERZjA.ttf',
    '600': 'http://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4nY1M2xLER.ttf',
    '600italic': 'http://fonts.gstatic.com/s/opensans/v28/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkxhjaVcUwaERZjA.ttf',
    '700': 'http://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf',
    '700italic': 'http://fonts.gstatic.com/s/opensans/v28/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkyFjaVcUwaERZjA.ttf',
    '800': 'http://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgshZ1y4nY1M2xLER.ttf',
    '800italic': 'http://fonts.gstatic.com/s/opensans/v28/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk0ZjaVcUwaERZjA.ttf',
    'italic': 'http://fonts.gstatic.com/s/opensans/v28/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkaVcUwaERZjA.ttf',
    'regular': 'http://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf',
  },
  scripts: [
    'cyrillic',
    'cyrillic-ext',
    'greek',
    'greek-ext',
    'hebrew',
    'latin',
    'latin-ext',
    'vietnamese'
  ],
  variants: [
    '300',
    'regular',
    '500',
    '600',
    '700',
    '800',
    '300italic',
    'italic',
    '500italic',
    '600italic',
    '700italic',
    '800italic'
  ]
};

const defaultTheme = {
  _id: '61a6b63e4d94e900424e44f0',
  projectName: 'New Theme',
  active: true,
  videoHost: 'Video Host',
  emailEndpoint: 'blank email endpoint',
  brandLogo1: '1639155698994-2560px-Warby_Parker_logo.svg.png',
  brandLogoWidth: 300,
  brandLogo2: '1639156182337-2560px-Warby_Parker_logo.svg.png',
  bgImage: '1639172769924-hand-painted-watercolor-pastel-sky-background_23-2148902771.jpeg',
  bgImageMobile: '1639172769924-hand-painted-watercolor-pastel-sky-background_23-2148902771.jpeg',
  headerImage: '1639156182337-2560px-Warby_Parker_logo.svg.png',
  takeawayImage: '1639172769924-hand-painted-watercolor-pastel-sky-background_23-2148902771.jpeg',
  takeawayVideoUrl: 'take-away-video-url.com',
  takeawayVideoThumbnailImage: '1639172769924-hand-painted-watercolor-pastel-sky-background_23-2148902771.jpeg',
  paddingTop: 30,
  paddingRight: 30,
  paddingBottom: 30,
  paddingLeft: 30,
  borderColor: '#cccccc',
  imageBorderColor: '#000000',
  enableImageBorder: false,
  brandColor1: '#f8f8f8',
  brandColor2: '#cccccc',
  textColor1: '#313240',
  textColor2: '#ffffff',
  textColor3: '#313240',
  textColor4: '#ffffff',
  footerBgColor: '#ffffff',
  linkTextColor: '#000000',
  pageHeaderText: 'Thanks for participating!',
  pagePrimaryText: 'Place any primary text here.',
  pageFooterText: 'Reach us at any of our social media handles',
  fontSizeHeader: 32,
  fontSizePrimary: 16,
  fontSizeFooter: 12,
  pageDisclaimerText: 'Share your picture with friends and family!',
  emailSubjectText: 'Thanks for your submission.',
  pageMetaTitle: 'New Post Submission',
  pageMetaDescription: 'User has submitted a new post',
  brandFbAcct: 'https://www.facebook.com/warbyparker/',
  brandIgAcct: 'https://www.instagram.com/warbyparker/?hl=en',
  brandTwitterAct: 'https://twitter.com/WarbyParker?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
  swapShareContent: false,
  enableDownloadLink: true,
  approvalQueueApproveMsg: '',
  approvalQueueDenyMsg: '',
  createdAt: '2021-11-30T23:39:42.305Z',
  updatedAt: '2021-12-10T21:46:11.747Z',
  frontendUrl: 'frontend-url.com',
  logoLinkUrl: 'https://google.com',
  headerFont: defaultFont,
  textFont: defaultFont,
  footerFont: defaultFont
};

export { defaultTheme, defaultFont };
